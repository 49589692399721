import Config from './config';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';

const app = feathers();
const restClient = rest(Config.serverRest);
app.configure(restClient.fetch(window.fetch.bind(window)));

const setCompetitionRiders = (teams) => {
	const riders = {};
	for (let t of teams) {
		for (let r of t.riders) {
			// {bib: 1, lastname: "POGAČAR", firstname: "Tadej", lastnameshort: "POGACAR", birthdate: "1998-09-21",…}
			r.teamName = t.name;
			//nameshort: "UAE TEAM EMIRATES"
			r.teamCategory = t.category;
			r.teamCode = t.code;
			r.jersey = t.jersey;

			// temporary until final switch
			r.jersey2 = t.jersey2;

			// temporary until final switch
			if (typeof r.firstname === 'string') {
				r.firstName = r.firstname;
			}
			if (typeof r.firstname === 'string') {
				r.lastName = r.lastname;
			}
			if (typeof r.uciid === 'string') {
				r.UCIID = r.uciid;
			}

			riders[r.bib] = r;
		}
	}

	return riders;
};

export default {
	async getCompetitions(query) {
		try {
			return await app.service('competitions').find({
				query: {
					'$sort': {startDate: 1},
					...query,
				}
			});
		} catch (exception) {
			throw new Error('Invalid request');
		}
	},
	async getCompetition(id) {
		const response = await fetch(Config.serverRest + '/competitions/' + id);
		if (response.status === 200) {
			const body = await response.json();

			if (typeof body.teams === 'object') {
				body.riders = setCompetitionRiders(body.teams);
			}
			return body;
		}

		throw new Error('Invalid request');
	},
	async getStage(stageId) {
		const response = await fetch(Config.serverRest + '/stages/' + stageId);
		if (response.status === 200) {
			const body = await response.json();
			return body;
		}

		throw new Error('Invalid request');
	},
	async getRiderDetails(uciid) {
		const response = await fetch(Config.serverRest + '/riders/' + uciid + '?withDetails=true');
		if (response.status === 200) {
			const body = await response.json();
			return body;
		}

		throw new Error('Invalid request');
	},
	async getCDFHistory(uciid, year, gender) {
		const response = await fetch(Config.serverRest + '/riders/' + uciid + '?withCDFHistory=true&gender=' + gender + '&year=' + year);
		if (response.status === 200) {
			const body = await response.json();
			return body;
		}

		throw new Error('Invalid request');
	},
};
