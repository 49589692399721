import API from '../components/api';
import {isEmpty} from '../components/tools';

export default (template) => {
	return {
		template,
		watch: {
			async '$route.params.competitionId'() {
				await this.getCompetition();
			},
		},
		data() {
			return {
				competitionError: null,
				competitionLoading: false,
				competition: null,
				competitionsError: null,
				competitionsLoading: false,
				competitions: [],
				currentStage: null,
			};
		},
		async created() {
			document.body.classList.add('coupeDeFrance');

			await this.getCDFCompetitionsSameYear();

			if (isEmpty(this.$route.params.competitionId) === true) {
				return this.openBestCompetition();
			}
			await this.getCompetition();
		},
		methods: {
			openBestCompetition() {
				const nowTime = new Date().getTime();

				if (isEmpty(this.competitions) === true) {
					return;
				}

				let bestCompetitionId = '';

				for (let competition of this.competitions) {
					// '2023-10-01'
					if (nowTime >= new Date(competition.startDate).getTime()) {
						bestCompetitionId = competition.id;
					}
				}

				// ouvrir la dernière manche connue ?

				if (bestCompetitionId === '') {
					bestCompetitionId = this.competitions[0].id;
				}

				return this.$router.push(`/coupeDeFrance/${this.$route.params.gender}/${this.$route.params.year}/${bestCompetitionId}`);
			},
			async getCDFCompetitionsSameYear() {
				this.competitionsLoading = false;
				this.competitionsError = null;

				try {
					this.competitionsLoading = true;
					this.competitions = await API.getCompetitions({
						coupeDeFrance: true,
						gender: this.$route.params.gender,
						tabsCdf: {
							$ne: 'null',
						},
						$select: ['id', 'name', 'startDate', 'stages', 'tabsCdf'],
						startDate: {
							$search: '(?i).*' + this.$route.params.year + '-.*',
						},
					});
				} catch (exception) {
					this.competitionsError = exception.message;
				} finally {
					this.competitionsLoading = false;
				}
			},
			async getCompetition() {
				this.competitionLoading = false;
				this.competitionError = null;

				try {
					this.competitionLoading = true;
					this.competition = await API.getCompetition(this.$route.params.competitionId);

					if (isEmpty(this.competition) === false) {
						if (typeof this.competition.language === 'string' && (['en', 'fr'].includes(this.competition.language.toLowerCase()) === true)) {
							this.$i18n.locale = this.competition.language.toLowerCase();
						}
					}

					if (isEmpty(this.competition.stages) === false) {
						this.currentStage = await this.getStage(this.competition.stages[0].id);

						this.improveCompetitionTabs();

						this.openBestTab();
					}
				} catch (exception) {
					this.competitionError = exception.message;
				} finally {
					this.competitionLoading = false;
				}
			},
			openBestTab() {
				if (isEmpty(this.$route.params.rankingTabId) === true) {
					if (isEmpty(this.competition.tabsCdf) === false) {
						return this.$router.push(`/coupeDeFrance/${this.$route.params.gender}/${this.$route.params.year}/${this.$route.params.competitionId}/` + this.competition.tabsCdf[0].id);
					}
				}
			},
			async getStage(stageId) {
				const stage = await API.getStage(stageId);
				return stage;
			},
			changeRoute(e) {
				this.$router.push(`/coupeDeFrance/${this.$route.params.gender}/${this.$route.params.year}/${e.target.value}/`);
			},
			improveCompetitionTabs() {
				if (isEmpty(this.competition.tabs) === false) {
					for (let tab of this.competition.tabs) {
						tab.id = tab.label.replace(/[^A-Za-z0-9]/g, '-');
						tab.displayed = false;
						tab.panelsCount = 0;

						for (let panel of tab.panels) {
							const events = this.stageEventsByCode(panel.eventsCodesFilters);
							panel.displayed = events.length > 0;
							if (panel.displayed === true) {
								tab.displayed = true;
								panel.events = events;
								tab.panelsCount++;
							}
						}
					}

					if (isEmpty(this.competition.tabsCdf) === false) {

//						this.competition.tabsCdf.push({
//							"label": "Classement",
//							"panels": [
//								{
//									"eventsCodesFilters": [
//										"EQU_EPR"
//									],
//									"id": "2024_GPI-Manche",
//									"jersey": null,
//									"label": "Manche",
//									"sponsorImage": "",
//									"sponsorLabel": null
//								},
//								{
//									"eventsCodesFilters": [
//										"EQU_GEN"
//									],
//									"id": "2024_GPI-G-n-ral",
//									"jersey": null,
//									"label": "Général",
//									"sponsorImage": "",
//									"sponsorLabel": null
//								}
//							]
//						});

						for (let tab of this.competition.tabsCdf) {
							tab.id = tab.label.replace(/[^A-Za-z0-9]/g, '-');
							tab.displayed = false;
							tab.panelsCount = 0;

							for (let panel of tab.panels) {
								const events = this.stageEventsByCode(panel.eventsCodesFilters);
								// mark events as Cdf for special display (event component)
								for (let ev of events) {
									ev.isCdf = true;
								}

								//console.log(panel.eventsCodesFilters, events);
								panel.displayed = events.length > 0;
								if (panel.displayed === true) {
									tab.displayed = true;
									panel.events = events;
									tab.panelsCount++;
								}
							}
						}
					}
				}
			},
			stageEventsByCode(codes) {
				if (this.currentStage === null) {
					return [];
				}
				if (typeof this.currentStage.rankingTypes === 'undefined') {
					return [];
				}
				return this.currentStage.rankingTypes.filter((e) => codes.includes(e.type) === true);
			},
		}
	};
};
